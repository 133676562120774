<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    // 解决ie 路由不能正确跳转
    function checkIE() {
      return (
        "-ms-scroll-limit" in document.documentElement.style &&
        "-ms-ime-align" in document.documentElement.style
      );
    }
    if (checkIE()) {
      window.addEventListener(
        "hashchange",
        () => {
          var currentPath = window.location.hash.slice(1);
          if (this.$route.path !== currentPath) {
            this.$router.push(currentPath);
          }
        },
        false
      );
    }
  },
  methods:{

  }
};
</script>

<style>
#app {
  position: relative;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'OPPOsans';
}
html,
body,
#app {
  height: 100%;
}

.el-drawer__body {
  overflow: auto;
}

.el-drawer__container ::-webkit-scrollbar {
  display: none;
}

input:disabled, textarea:disabled{
  opacity: 1;
  -webkit-text-fill-color: #222222;
  background-color: #fff !important;
}
</style>
