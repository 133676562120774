// 设置localstaorage
export const setStorage = (key,value)=>{
    if (!key) return;
	if (typeof value !== 'string') {
		value = JSON.stringify(value);
	}
	window.localStorage.setItem(key, value);
}
// 获取 localstorage
export const getStorage = (key)=>{
    if (!key) return;
	let value = window.localStorage.getItem(key);
    if (value !== null) {
        try {
            value = JSON.parse(value);
        } catch (e) {
            value = value;
        }
    }
    return value;
}
// 删除localstorage
export const removeStore = key => {
	if (!key) return;
	window.localStorage.removeItem(key);
}