import Vue from 'vue'
import Router from 'vue-router'

import Login from '../views/Login'
import { getStorage } from '@/assets/util/localStorage'

Vue.use(Router)

// 解决二级菜单中路由跳转时的报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new Router({
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/login', component: Login },
    { path: '/register', component: () => import('../views/Register') },
    { path: '/retrievePassword', component: () => import('../views/RetrievePassword') },
    {
      path: '/bighome',
      component: () => import('../views/BigHome'),
      redirect: '/home',
      children: [
        /**
         * 学校账号相关路由
         */

        //学校账号下的导入以及导出自评报告
        {
          path: '/home/preview',
          component: () => import('../views/school-home/preview'),
        },
        {
          path: '/home/import',
          component: () => import('../views/school-home/import'),
        },
        // 学校账号课程自评报告
        // /home/clazzImport
        {
          path: '/home/(clazzImport)',
          component: () => import('../views/school-home/ClazzImport'),
        },
        //学校账号下的任务管理
        {
          path: '/home/task/ongoing',
          component: () => import('../views/task/ongoingTask'),
        },
        {
          path: '/home/task/completed',
          component: () => import('../views/task/completedTask'),
        },
        {
          path: '/home/task/all',
          component: () => import('../views/task/allTask'),
        },
        //学校账号下的创建账号以及删除账号
        {
          path: '/home/createAccount',
          component: () => import('../views/school-account/create'),
        },
        {
          path: '/home/cancelAccount',
          component: () => import('../views/school-account/cancel'),
        },

        /**
         * 课程评审相关路由
         */

        // 课程评审 查看所有课程页面
        {
          path: '/course/viewCourse',
          component: () => import('../views/course-command/viewCourse'),
          name: 'viewCourse',
          meta: {
            keepAlive: true, // 需要缓存
            isBack: false,
          },
        },
        // 课程评审 查看课程所有评审流程页面
        {
          path: '/course/viewProcess',
          component: () => import('../views/course-command/viewProcess'),
          name: 'viewProcess',
          meta: {
            keepAlive: false, // 需要缓存
            isBack: false,
          },
        },
        // 课程评审 评审详细流程
        {
          path: '/course/reviewProcess',
          component: () => import('../views/course-command/reviewProcess'),
          name: 'reviewProcess',
        },
        // 课程评审 课程负责人上传材料页
        {
          path: '/course-pensonIC/Import',
          component: () => import('../views/course-command/uploadMaterials.vue'),
        },
        // 课程评审 复评上传材料页
        {
          path: '/course/assessAgain',
          component: () => import('../views/course-command/assessAgain.vue'),
        },
        // 课程评审 课程专家组长 查看流程的所有专家评审
        {
          path: '/course/checkReview',
          component: () => import('../views/course-command/checkReview'),
        },
        // 课程评审 评审指南
        {
          path: '/course-assessExp/AssessGuide',
          component: () => import('../views/course-command/assessGuide.vue'),
        },
        // 课程评审 评审详情页
        {
          path: '/course-assessExp/assessDetail',
          component: () => import('../views/course-command/assessDetail.vue'),
        },
        // 课程评审 专家组长小组上传小组意见
        {
          path: '/course-head-assessExp/GroupAccess',
          component: () => import('../views/course-command/groupAccess.vue'),
        },
        // 课程评审 查看组长小组意见
        {
          path: '/course-head-assessExp/checkOpinion',
          component: () => import('../views/course-command/checkOpinion.vue'),
        },

        /**
         * 用户页面相关路由
         */

        // 用户 所有账号的修改密码
        {
          path: '/home/changePwd',
          component: () => import('../views/user/changePwd'),
        },
        // 用户 所有账号的首页
        {
          path: '/home/home',
          component: () => import('../views/user/home'),
        },
        // 用户 所有账号的修改个人信息
        {
          path: '/home/infomation',
          component: () => import('../views/user/personalInformation'),
        },

        /**
         * 专业评审相关路由
         */

        // 专业评审 查看所有专业
        {
          path: '/experts/viewMajors',
          component: () => import('../views/major-command/viewMajor'),
          name: 'viewMajors',
          meta: {
            keepAlive: true,
            isBack: false,
          },
        },
        // 专业评审 查看专业的所有评审流程
        {
          path: '/experts/viewProcess',
          component: () => import('../views/major-command/viewProcess'),
          name: 'viewMajorProcess',
          meta: {
            keepAlive: false,
            isBack: false,
          },
        },
        // 专业评审 评审指南
        {
          path: '/experts/guide',
          component: () => import('../views/major-command/guide'),
        },
        // 专业评审 查看评审流程的详细信息
        {
          path: '/experts/reviewProcess',
          component: () => import('../views/major-command/reviewProcess'),
          name: 'reviewMajorProcess',
        },
        // 专业评审 负责人上传自评报告
        {
          path: '/experts/uploadMaterials',
          component: () => import('../views/major-command/uploadMat'),
        },
        // 专业评审 评审专家评审
        {
          path: '/experts/AssessReportDetail',
          component: () => import('../views/major-command/AssessReportDetail'),
          meta: {
            isBack: false,
          },
        },
        // 专业评审 评审专家组长评审
        {
          path: '/experts/assessDetail',
          component: () => import('../views/major-command/assessDetail'),
          meta: {
            isBack: false,
          },
        },
        // 专业评审 专家组长小组上传小组意见
        // {
        //   path: '/experts/GroupAccess',
        //   component: () => import('../views/major-command/groupAccess.vue'),
        // },
        // 课程评审 复评上传材料页
        {
          path: '/experts/assessAgain',
          component: () => import('../views/major-command/assessAgain.vue'),
        },
        // 专业评审 查看所有组长小组意见
        {
          path: '/experts/checkOpinion',
          component: () => import('../views/major-command/checkOpinion'),
        },
        // 专业评审 组长/负责人查看此流程的所有评审
        {
          path: '/experts/checkReview',
          component: () => import('../views/major-command/checkReview'),
        },

        //df测试使用
        {
          path: '/major-archive-manager/dfTest',
          component: () => import('../views/major-command/dfTest'),
          meta: {
            keepAlive: true,
          },
        },

        /**
         * 专业归档相关路由
         */

        //专业归档指南
        {
          path: '/major-archive-manager/majorArchiveGuide',
          component: () => import('../views/major-archive-command/dfMajorArchiveGuide.vue'),
        },

        // 专业归档（超级管理员） 上传专业归档的模板材料
        {
          path: '/major-archive-manager/uploadMT',
          component: () => import('../views/major-archive-command/uploadMT.vue'),
        },
        // 专业归档 查看专业
        {
          path: '/major-archive-manager/viewMajor',
          component: () => import('../views/major-archive-command/viewMajor.vue'),
          name: 'arcViewMajors',
          meta: {
            keepAlive: false,
            isBack: false,
          },
        },
        // 专业归档 查看批次
        {
          path: '/major-archive-manager/viewBatch',
          component: () => import('../views/major-archive-command/viewBatch.vue'),
          name: 'arcViewBatch',
          meta: {
            keepAlive: true,
            isBack: false,
          },
        },
        // 专业归档 查看批次详情信息
        {
          path: '/major-archive/viewBatch_detail',
          component: () => import('../views/major-archive-command/viewBatch_detail.vue'),
          name: 'arReviewBacth',
        },
        // 专业归档 批次上传材料
        {
          path: '/major-archive-manager/uploadMaterials',
          component: () => import('../views/major-archive-command/uploadMaterials.vue'),
        },
        // 专业归档 开始评审批次
        {
          path: '/major-archive-manager/reviewBatch',
          component: () => import('../views/major-archive-command/reviewBatch.vue'),
        },
        // 专业归档 查看批次的所有评审意见
        {
          path: '/major-archive-manager/checkBatchReview',
          component: () => import('../views/major-archive-command/checkBatchReview.vue'),
        },

        // 系统指南页面
        {
          path: '/home/guide',
          component: () => import('../views/user/guide.vue'),
        },
      ],
    },
  ],
})

// 添加路由守卫 判断是否登录
router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/register' || to.path === '/retrievePassword') {
    next()
  } else {
    let refreshToken = getStorage('refreshToken')
    //token有效期两天
    if (refreshToken === null || refreshToken === '' || new Date().getTime() - refreshToken.setTime > 1000 * 60 * 60 * 24 * 2) {
      Vue.prototype.$message.error('登录已过期，请重新登陆')
      next('/login')
    } else {
      next()
    }
  }
})

export default router
