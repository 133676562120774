import router from "@/router"
import axios from "axios"
import Vue from "vue"
import qs from "qs"

const baseUrl = process.env.VUE_APP_API || "https://evaluation.moxiaoxiao.net"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = baseUrl
axios.defaults.withCredentials = true;

// 是否正在刷新token
let isRefreshing = false;
// 重新请求的队列
let requests = [];
axios.interceptors.response.use(
  res => {
    if(!res.data.code) return res; // 某些下载文件接口没有返回code
    if (res.data.code === 401) {
      console.log('接口返回401',this.$store);
      this.$store.commit('del_token')
      Vue.prototype.$message.error(res.data.message)
      router.replace({
        path: '/login',
        query: { redirect: router.currentRoute.fullPath }
      })
      // 登录成功后跳转入当前浏览的页面
    }else if(res.data.code === 403){
      console.log(res);
      Vue.prototype.$message.error(res.data.message)
      

    }else if(res.data.code !== 200) {
      Vue.prototype.$message.error(res.data.message)
     }

    return res
  },
  err => {
    console.log(err);
    Vue.prototype.$message.error(err.message);
    return err
  }
)

// 请求头附带token
axios.interceptors.request.use(config => {
  if (config.url==='/auth/oauth/token') {
    config.data = qs.stringify(config.data);
   }
  if(config.url!== '/auth/oauth/token'){
    const token = localStorage.getItem("accessToken")
    if (token) {
      config.headers.Authorization = 'Bearer '+token
    }
  }

  return config;
},
  err => {
    return err
  })
Vue.prototype.axios = axios
Vue.prototype.baseURL = "https://evaluation.moxiaoxiao.net"
Vue.prototype.previewURL = "https://view.officeapps.live.com/op/view.aspx?src="
Vue.prototype.previewClazzTplSrc = "http://evaluation.moxiaoxiao.net/teaching/clazz/preview/template?path="
Vue.prototype.previewClazzRepSrc = "http://evaluation.moxiaoxiao.net/teaching/clazz/preview/uploadFile?path="
Vue.prototype.previewMajorRepSrc = "http://evaluation.moxiaoxiao.net/teaching/report/preview/"
Vue.prototype.handleDownload = function (fileData = new Blob(), fileName) {
  const filename = fileName;
  var downloadElement = document.createElement("a");
  var href = window.URL.createObjectURL(fileData);
  downloadElement.href = href;
  downloadElement.download = decodeURIComponent(filename);
  document.body.appendChild(downloadElement);
  downloadElement.click();
  document.body.removeChild(downloadElement);
  window.URL.revokeObjectURL(href);
}


export default axios













