<template>
  <div class="login" v-loading="loading">
    <div class="wrapper">
      <!-- <p class="title">广东工业大学本科教学质量评估管理系统</p> -->
      <div class="box">
        <img src="../assets/images/login_logo.png" alt="" />
      </div>
      <p class="title">本科教学质量评估监测中心</p>

      <div class="contain">
        <el-form
          class="form"
          :rules="rules"
          label-position="top"
          :model="loginForm"
          ref="loginForm"
          @submit.native.prevent="submitForm"
          v-if="!isChangePwd"
        >
          <el-form-item prop="username" label="账号">
            <el-input
              placeholder="请输入账号"
              v-model="loginForm.username"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password" label="密码">
            <el-input
              placeholder="请输入密码"
              v-model="loginForm.password"
              type="password"
            ></el-input>
          </el-form-item>
          <div class="forgetBtn_div">
            <el-button type="text" class="forgetBtn" @click="retrievePassword"
              >忘记密码</el-button
            >
            <el-button type="text" class="forgetBtn" @click="register"
              >立即注册</el-button
            >
            <!-- <el-button type="text" class="forgetBtn" @click="Tochat">私聊</el-button> -->
          </div>
          <el-form-item class="login_btn_item">
            <el-button type="primary" class="login-button" native-type="submit"
              >确认登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { getUserInfo, getRefreshToken } from "@/api/userInterface";
// 引入crypto加密封装函数
// import { Decrypt, Encrypt } from "@/assets/util/secrect";
// 引入rsa 加密封装函数
import {encryptRSA} from "@/assets/util/rsa";
import { setStorage, getStorage } from "@/assets/util/localStorage";
import Footer from "@/components/footer/Footer.vue";

export default {
  components: {
    Footer,
  },
  data() {
    return {
      loading: false,
      loginForm: {},
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      isChangePwd: false,
    };
  },
  async created() {
    // 自动登录
    // 判断token是否过期
    let refreshToken = getStorage("refreshToken");
    if (refreshToken !== null && refreshToken !== undefined) {
      if (
        new Date().getTime() - refreshToken.setTime <
        1000 * 60 * 60 * 24 * 2
      ) {
        // 获取新的accesstoken 和 refreshtoken
        let { data: res } = await this.$store.dispatch("refresh");
        this.refreshLock = false;
        this.$store.commit("set_token", {
          accessToken: res.data.token,
          refreshToken: {
            token: res.data.refreshToken,
            setTime: new Date().getTime(),
          },
        });
        setStorage("refreshTime", (res.data.expiresIn - 5) * 1000);

        // 用新的token 请求获取用户信息
        let { data: res2 } = await getUserInfo();
        if (res2.code == 200) {
          setStorage("realName", res2.data.realName);
          setStorage("userType", res2.data.userType);
          setStorage("userId", res2.data.id);
          const usertype = getStorage("userType");
          if(this.$store.state.accessToken){
          this.$router.push({
            path: "/home/home",
            query: { type: usertype },
          });
        }
      }
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.loginForm.validate(async (valid) => {
        // element-ui 提供的表单预验证
        if (valid) {
          /*
           * 此处对账号密码进行加密(RSA加密)(暂无)
           */
          // this.loginForm.password = encryptRSA(this.loginForm.password);
          // let { data: res } = await login(this.loginForm);
          this.loading = true;
          this.loginForm.grant_type = "password";
          this.loginForm.client_id = "c1";
          this.loginForm.client_secret = "secret";
          let { data: res } = await getRefreshToken(this.loginForm);
          this.loading = false;
          if (res.code == 200) {

            this.$store.commit("set_token", {
              accessToken: res.data.token,
              refreshToken: {
                token: res.data.refreshToken,
                setTime: new Date().getTime(),
              },
            });
            setStorage("refreshTime", (res.data.expiresIn - 60) * 1000);
            this.loading = true;
            let { data: res2 } = await getUserInfo();
            this.loading = false;
            if (res2.code == 200) {
              setStorage("realName", res2.data.realName);
              setStorage("userType", res2.data.userType);
              setStorage("userId", res2.data.id);

              this.$router.push({
                path: "/home/home",
                query: { type: res2.data.userType },
              });
            }
          }
        }
      });
    },
    register() {
      this.$router.push({ path: "/register" });
    },
    retrievePassword() {
      this.$router.push({ path: "/retrievePassword" });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  font-family: "OPPOsans";
  height: 100%;
  // background: rgb(50, 60, 84);
  background: url(../assets/images/login_background.png) no-repeat;
  background-size: cover;
  position: relative;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .box {
    margin-bottom: 10px;
    width: 330px;
    height: 84px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    color: #000;
    font-style: normal;
    text-align: center;
    font-family: "OPPOsans";
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 20px;
    user-select: none;
  }
}

.contain {
  width: 450px;
  // height: 310px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  padding-top: 45px;
  .form {
    width: 100%;
    padding: 0 45px;
    box-sizing: border-box;
    // form表单 的label 的文字样式
    /deep/.el-form-item__label {
      font-weight: 500;
      color: #000;
      font-size: 16px;
      line-height: 18px;
    }
    // 改输入框input 的样式
    /deep/ .el-input__inner {
      // border-radius: 4px;
      &::placeholder {
        font-family: "OPPOsans";
        font-size: 14px;
      }
    }
    // form 表单label 旁边的小星星改成竖杠
    /deep/
      .el-form-item.is-required:not(.is-no-asterisk)
      > .el-form-item__label:before {
      content: "";
      display: inline-block;
      width: 2px;
      height: 16px;
      margin-right: 10px;
      transform: translate(4px, 2px);
      background-color: #a02333;
    }
    .login_btn_item {
      display: flex;
      justify-content: center;
    }
    .login-button {
      height: 40px;
      width: 116px;
    }
    // /deep/.el-input__inner:focus {
    // border: 1px solid transparent;
    // }
    // .el-button--primary {
    //   border-radius: 6px;
    //   background-color: @login-btn-color;
    //   border: 1px solid #fff;
    // }
    .forgetBtn_div {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .forgetBtn {
        font-family: "OPPOsans";
        font-weight: 500;
        font-size: 12px;
        line-height: 0;
      }
    }
  }
}
</style>
