<!-- footer -->
<template>
  <div class="footer">
    <a href="http://beian.miit.gov.cn" target="_Blank">粤ICP备2021110540号-1</a>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {},

  mounted() {},
};
</script>

<style scoped>
a {
  color: #000;

}

.footer {
  font-size: 12px;
  color: #000;
  /* text-align: center; */
  /* padding: 50px; */
  /* background-color: #3c3a3a;*/
   position: absolute; 
   z-index: 999; 
   left: 50%; 
   bottom: 20px; 
   transform: translateX(-50%);
}
</style>