import axios from './index.js';

// 1.所有账号的更新密码
export const updatePwd = data => axios.put("/teaching/user/password", data);
// 2.登录获取token / 更新token
export const getRefreshToken = data => axios.post("/auth/oauth/token", data);
// 3.用token 获取用户信息
export const getUserInfo = data => axios.get("/teaching/user/currentUserInfo", data);
// export const getUserInfo = data => axios.get("/teaching/user/getCurrentUserInfo", data);

// 4.注册账号（新）
export const createAccount = data => axios.post("/teaching/user/createAccount", data);
// 5.发送验证码（新）
export const sendCode = (email) => axios.post(`/teaching/user/send?email=${email}`);
// 6.获取个人详细信息（新）

// 7.忘记密码重置密码（新）
export const resetPwd = data => axios.post("/teaching/user/forgetPassword", data);
// 8.忘记密码的验证码（新）
export const sendForget = email => axios.post(`/teaching/user/sendForget?email=${email}`);

// 9.获取个人信息
export const searchUserInfo = id => axios.get("/teaching/user/detail", { params: { id } });
// 10.修改个人信息
export const updateUserInfo = data => axios.post("/teaching/user/update", data);


// 获取通知
export const getNotification = (pageNum) => axios.get('/teaching/notify/notification', {
  params: {
    pageNum
  }
})


// 忽略通知
export const ignoreNotification = (notificationId) => axios.put('/teaching/notify/notification', {
  notificationId
})