import Vue from 'vue'
import Vuex from 'vuex'

import { setStorage, getStorage, removeStore } from "@/assets/util/localStorage"
import { getRefreshToken } from '@/api/userInterface'
import createPersistedState from 'vuex-persistedstate'
// import { Decrypt } from '@/assets/util/secrect'
// import EncryptRSA from "@/assets/util/rsa"
Vue.use(Vuex)


const store = new Vuex.Store({
    state: {
        accessToken: getStorage('accessToken'),
        refreshToken: getStorage('refreshToken'),
        mainDom: null,
        scrollPosition: [], //对应页面滚动条的位置集合 default:{path:'',position:0,data:[],pageNum:0}
        pageConfig: { pageSize: 0, pageNum: 0 }
    },
    mutations: {
        set_token(state, { accessToken, refreshToken }) {
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
            setStorage("accessToken", accessToken)
            setStorage("refreshToken", refreshToken)
        },
        del_token(state) {
            state.accessToken = "";
            state.refreshToken = "";
            removeStore("accessToken")
            removeStore("refreshToken")
        },
        setScroll(state, { path, position,data,pageNum }) {
            /**
             * 存储每个页面的滚动条位置
             */
            let isExit = false;
            if (state.scrollPosition.length) {
                state.scrollPosition.forEach((item) => {
                    if (item.path === path) {
                        item.position = position;
                        item.data = data;
                        item.pageNum = pageNum;
                        isExit = true;
                        
                    }
                })
                if (!isExit) {
                    console.log(11);
                    state.scrollPosition.push({ path, position,data,pageNum });
                }

            } else {
                state.scrollPosition.push({ path, position,data,pageNum });
            }
        },
        delScroll(state, path) {
            for (let item in state.scrollPosition) {
                if (state.scrollPosition[item].path == path) {
                    state.scrollPosition.splice(1, item);
                    return;
                }
            }
        }
    },
    actions: {
        refresh(context) {
            let params = {
                grant_type: 'refresh_token',
                client_id: 'c1',
                client_secret: 'secret',
                refresh_token: context.state.refreshToken.token
            }
            return getRefreshToken(params)
        }
    },
    getters: {
    },
    plugins:[createPersistedState({
        storage:window.sessionStorage, // 存储到session
        reducer(val){
            return {
                scrollPosition:val.scrollPosition
            }
        }
    })]
})

export default store
